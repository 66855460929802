<template>
  <section class="invoice-preview-wrapper">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="invoiceData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-invoice-list'}"
        >
          Invoice List
        </b-link>
        for other invoices.
      </div>
    </b-alert>

    <b-row
      v-if="invoiceData"
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="invoice-header">
              <div class="invoice-remark">
                Увага! Оплата цього рахунку означає погодження з умовами поставки товарів. Повідомлення про оплату є обов'язковим, в іншому випадку не
                  гарантується наявність товарів на складі. Товар відпускається за фактом надходження коштів на п/р Постачальника, самовивозом, за наявності
                  довіреності та паспорта.
              </div>
              <div class="invoice-header-title">Зразок заповнення платіжного доручення</div>
              <div class="invoice-header-info">
                <div class="invoice-header-info-line">
                  <div class="invoice-header-info-line-title">Отримувач</div>
                  <div class="invoice-header-info-value">{{ info.title }}</div>
                </div>
                <div class="invoice-header-info-line">
                  <div class="invoice-header-info-line-title">Код</div>
                  <div class="invoice-header-info-value block">{{ info.code }}</div>
                </div>
                <div class="invoice-header-info-footer">
                  <div class="invoice-header-info-footer-block">
                    <div class="invoice-header-info-line-title">Банк отримувача</div>
                    <div class="invoice-header-info-value line">{{ info.bank }}</div>
                  </div>
                  <div class="invoice-header-info-footer-block">
                    <div class="invoice-header-info-line-title center">КРЕДИТ рах. №</div>
                    <div class="invoice-header-info-value block">{{ info.ra }}</div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="invoice-body">
              <div class="invoice-title">Рахунок на оплату № {{ invoiceData.invoice.id }} від {{ getFormatedDate(invoiceData.invoice.date) }} р.</div>
              <div class="invoice-body-item">
                <div class="invoice-body-item-title">Постачальник:</div>
                <div class="invoice-body-item-text">
                  <div class="invoice-body-item-text-title">{{ info.title}}</div>
                  <div class="invoice-body-item-text-desc">
                    {{ fullTextWithBank }},<br>
                    {{ fullTextWithAddress }}<br>
                    код за ЄДРПОУ {{ info.code }}, ІПН {{ info.ipn }}<br>
                    <!-- {{ fullTextAfter }}-->
                  </div>
                </div>
              </div>
              <div class="invoice-body-item">
                <div class="invoice-body-item-title">Покупець:</div>
                <div class="invoice-body-item-text">
                  <div class="invoice-body-item-text-title">{{ invoiceData.company.title }}</div>
                  <div class="invoice-body-item-text-desc">
                    Тел.: {{ invoiceData.company.phone }}
                  </div>
                </div>
              </div>
              <div class="invoice-body-item-bottom">
                <div class="invoice-body-item-bottom-title">Договір поставки:</div>
                <div class="invoice-body-item-bottom-value">№ {{ invoiceData.company.contract }}</div>
              </div>
            </div>
          </b-card-body>

          <b-card-body class="invoice-padding pb-0">
            <table class="invoice-table">
              <thead>
                <tr>
                  <th>№</th>
                  <th>Товари (роботи, послуги)</th>
                  <th colspan="2">Кількість</th>
                  <th>Ціна з ПДВ</th>
                  <th>Сума з ПДВ</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(order, index) in invoiceData.order" :key="index">
                  <td style="text-align: center;">{{ index + 1 }}</td>
                  <td>{{ order.title }}</td>
                  <td style="text-align: right;">{{ order.unit.amount }}</td>
                  <td>{{ order.unit.type }}</td>
                  <td style="text-align: right;">{{ parseFloat(order.price / 100).toFixed(2) }}</td>
                  <td style="text-align: right;">{{ parseFloat((order.unit.amount * order.price) / 100).toFixed(2) }}</td>
                </tr>
              </tbody>
            </table>
          </b-card-body>

          <b-card-body class="invoice-padding pb-0">
            <div class="invoice-footer">
              <div class="invoice-total">
                <div class="invoice-total-item">
                  <div class="invoice-total-item-title">Разом:</div>
                  <div class="invoice-total-item-value">{{ parseFloat((invoiceData.invoice.price.digit) / 100).toFixed(2) }}</div>
                </div>
                <div class="invoice-total-item">
                  <div class="invoice-total-item-title">У тому числі ПДВ:</div>
                  <div class="invoice-total-item-value">{{ parseFloat((invoiceData.invoice.vat.digit) / 100).toFixed(2) }}</div>
                </div>
              </div>
              <div class="invoice-total-text">
                <div class="invoice-total-text-desc">Всього найменувань {{ invoiceData.order.length }}, на суму {{ parseFloat((invoiceData.invoice.price.digit) / 100).toFixed(2) }} грн.</div>
                <div class="invoice-total-text-item"><span>{{ capitalizeFirstLetter(invoiceData.invoice.price.human.main) }}</span> {{ uah(invoiceData.invoice.price.digit) }} {{ invoiceData.invoice.price.human.coin }} {{ coin(parseInt(invoiceData.invoice.price.human.coin)) }}</div>
                <div class="invoice-total-text-item">У т.ч. ПДВ: <span>{{ capitalizeFirstLetter(invoiceData.invoice.vat.human.main) }}</span> {{ uah(invoiceData.invoice.vat.digit) }} {{ invoiceData.invoice.vat.human.coin }} {{ coin(parseInt(invoiceData.invoice.vat.human.coin)) }}</div>
              </div>
              <div class="invoice-total-person-wrapper">
                <div class="invoice-total-person">
                  <div class="invoice-total-person-title">Виписав(ла):</div>
                  <div class="invoice-total-person-value">{{ info.signedPerson }}</div>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>

          <!-- Button: Send Invoice -->
          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            @click="printInvoice"
          >
            Роздрукувати
          </b-button>

        </b-card>
      </b-col>
    </b-row>

    <invoice-sidebar-send-invoice />
    <invoice-sidebar-add-payment />
  </section>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api';
import store from '@/store';
import router from '@/router';
import {
  BRow, BCol, BCard, BCardBody, BButton, BAlert, BLink, VBToggle,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import invoiceStoreModule from '../invoiceStoreModule';
import InvoiceSidebarSendInvoice from '../InvoiceSidebarSendInvoice.vue';
import InvoiceSidebarAddPayment from '../InvoiceSidebarAddPayment.vue';

export default {
  data() {
    return {
      info: {
        title: 'ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ "АФЕС КАРД"',
        bank: 'ПАТ "ПУМБ", м. Київ',
        code: '45596561',
        ipn: '455965624126',
        ra: 'UA023348510000000026000246943',
        signedPerson: 'Фесенко Олександр Олександрович'
      },
    };
  },
  computed: {
    fullTextWithBank() {
      return 'п/р ' + this.info.ra + ' у банку ' + this.info.bank;
    },
    fullTextWithAddress() {
      return 'УКРАЇНА, 58005, ЧЕРНІВЕЦЬКА ОБЛ, ЧЕРНІВЕЦЬКИЙ Р-Н, М. ЧЕРНІВЦІ,' +
          'ВУЛ. ВОЛОДИМИРА ВИНИЧЕНКА, БУД. 20, оф. 34, тел.: +38 (044) 334-50-34,';
    },
    fullTextAfter() {
      return 'Платник податку на прибуток на загальних підставах';
    },
  },
  mounted() {
    if (window.innerWidth <= 768) {
      setTimeout(() => {
        window.print();
        window.history.back();
      }, 1000);
    }
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BAlert,
    BLink,
    InvoiceSidebarAddPayment,
    InvoiceSidebarSendInvoice,
  },
  methods: {
    getFormatedDate(date) {
      const dateFormat = new Date(date);

      const months = [
        'січня',
        'лютого',
        'березня',
        'квітня',
        'травня',
        'червня',
        'липня',
        'серпня',
        'вересня',
        'жовтня',
        'листопада',
        'грудня',
      ];

      return (dateFormat.getDate() > 9 ? '' : '0') + dateFormat.getDate() + ' ' + months[dateFormat.getMonth()] + ' ' + dateFormat.getFullYear();
    },
    getFormattedContractDate(date){
      const dateFormat = new Date(date);

      return (dateFormat.getDate() > 9 ? '' : '0') + dateFormat.getDate() + '.' + (dateFormat.getMonth() > 9 ? '' : '0') + dateFormat.getMonth() + '.' + dateFormat.getFullYear();
    },  
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    uah(n) {
      const plural = (n%10==1 && n%100!=11 ? 0 : n%10>=2 && n%10<=4 && (n%100<10 || n%100>=20) ? 1 : 2);
      const pluralVariants = [
        'гривня',
        'гривні',
        'гривень',
      ];

      return pluralVariants[plural];
    },
    coin(n) {
      const plural = (n%10==1 && n%100!=11 ? 0 : n%10>=2 && n%10<=4 && (n%100<10 || n%100>=20) ? 1 : 2);
      const pluralVariants = [
        'копійка',
        'копійки',
        'копійок',
      ];

      return pluralVariants[plural];
    },
    
  },
  setup() {
    const invoiceData = ref(null);

    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice';

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    store.dispatch('app-invoice/fetchInvoice', { id: router.currentRoute.params.id })
      .then(response => {
        invoiceData.value = response.data;
        console.log(response.data.invoice.vat.digit);
      })
      .catch(error => {
        console.log(error);
        invoiceData.value = undefined;
      });

    const printInvoice = () => {
      window.print();
    };

    return {
      invoiceData,
      printInvoice,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.invoice-header {
  width: 90%;
  margin: 0 auto;
}
.invoice-remark {
  text-align: center;
  font-size: 12px;
  padding: 2px 0;
  margin-bottom: 10px;
}

.invoice-header-title {
  font-size: 14px;
  font-weight: 800;
  margin-bottom: 5px !important;
  text-align: center !important;
}

.invoice-header-info-line {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.invoice-header-info {
  padding: 20px 50px;
}

.invoice-header-info-line-title {
  min-width: 100px;
}

.invoice-header-info-value {
  font-weight: 800;
} 

.invoice-header-info-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.invoice-header-info-footer-block {
  display: flex;
  flex-direction: column;
  min-width: 207px;
}

.invoice-header-info-footer-block .invoice-header-info-line-title {
  margin-bottom: 5px;
}

.invoice-body {
  margin-top: 25px;
}

.invoice-title {
  font-size: 20px;
  font-weight: 800;
  padding-left: 5px;
  text-align: left !important;
  margin-bottom: 15px !important;
}

.invoice-body-item {
  display: flex;
  margin-bottom: 10px;
}

.invoice-body-item-title {
  font-size: 14px;
  min-width: 150px;
  text-decoration: underline;
}

.invoice-body-item-text-title {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 800;
}

.invoice-body-item-text-desc {
  padding-left: 10px;
}

.invoice-body-item-bottom {
  display: flex;
  font-size: 14px;
  min-width: 150px;
}

.invoice-body-item-bottom-title {
  margin-right: 35px;
}

.invoice-table {
  width: 100%;
}

.invoice-table th {
  text-align: center;
  padding: 10px 0;
  border: 1px solid black;
}

.invoice-table td {
  padding: 2px 10px;
  border: 1px solid black;
}

.invoice-footer {
  margin-top: 15px;
}

.invoice-total {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.invoice-total-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 800;
}

.invoice-total-item-title {
  min-width: 180px;
  text-align: right;
}
.invoice-total-item-value {
  min-width: 160px;
  text-align: right;
}

.invoice-total-text-desc {
  font-size: 12px;
}

.invoice-total-text-item {
  font-size: 14px;
  font-weight: 800;
}

.invoice-total-text {
  padding-bottom: 10px;
}
.invoice-total-person {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 550px;
  margin-top: 20px;
}

.invoice-total-person-title {
  font-size: 14px;
  font-weight: 800;
  margin-right: 200px;
}

.invoice-total-person-value {
  font-size: 12px;
}

.invoice-total-person-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

</style>

<style lang="scss">
@media print {
  
  @page { margin: 0; }

  html, body {
    height: auto;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  .invoice-remark {
    border: 1px solid black;
    color: black;
  }

  .invoice-header-title {
    color: black;
  }

  .invoice-header-info {
    display: block;
    border: 1px solid black;
    color: black;
  }

  .invoice-header-info-value.block {
    padding: 3px 20px;
    border: 1px solid black;
  }

  .invoice-header-info-value.line {
    border-bottom: 1px solid black;
  }

  .invoice-header-info-line-title.center {
    text-align: center;
  }

  .invoice-body {
    color: black;
  }

  .invoice-title {
    border-bottom: 2px solid black;
  } 

  .invoice-table {
    border: 2px solid black;
    color: black;
  }

  .invoice-table thead {
    background: #ededed !important;
  }

  .invoice-table th {
    text-align: center;
    padding: 10px 0;
    border: 1px solid black;
    background: #ededed !important;
  }

  .invoice-table td {
    padding: 2px 4px;
    border: 1px solid black;
  }

  .invoice-footer {
    color: black;
  }

  .invoice-total-text  {
    border-bottom: 2px solid black;
  }

  .invoice-total-person {
    border-bottom: 1px solid black;
  }

  // Global Styles
  body {
    background-color: transparent !important;
    color: black;
    font-family: 'Arial';
  }

  .content-header{
    display: none !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
